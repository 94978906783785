/* eslint-disable no-useless-escape */
import React from "react";
import Salert from "react-s-alert";
import PropTypes from "prop-types";

import {
  mobile_rule,
  pwd_rule,
  phone_code_rule,
  nickname_rule,
  rucaptcha_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../shared/CustomizeInput";
import FormWrapper from "../utils/FormWrapper";

const TIME = 60;

class SignupForm extends React.Component {
  state = {
    isSend: false,
    time: TIME,
    src: "/rucaptcha/",
  };

  // safari 某些版本中 这里 直接改src state 不能正确触发 view update
  // 排查出是safari图片缓存机制的问题 加时间戳解决
  updateRucaptcha() {
    this.setState({
      src: "/rucaptcha/?" + Date.now(),
    });
  }

  sendCode() {
    const {
      data: { mobile, _rucaptcha },
    } = this.props;

    if (mobile.value && mobile.valid) {
      if (_rucaptcha.value && _rucaptcha.valid) {
        if (!this.state.isSend) {
          $.ajax({
            url: "/phone_verify_code",
            method: "POST",
            datType: "json",
            data: {
              mobile: mobile.value,
              _rucaptcha: _rucaptcha.value,
            },
            success: () => {
              const timer = setInterval(() => {
                if (this.state.time === 0) {
                  clearInterval(timer);
                  this.setState({
                    isSend: false,
                    time: TIME,
                  });
                  this.updateRucaptcha();
                }
                this.setState({
                  time: this.state.time - 1,
                });
              }, 1000);
              this.setState({
                isSend: true,
              });
            },
            error: xhr => {
              console.log(xhr.status);
              if (xhr.status === 422) {
                Salert.error(xhr.responseJSON.errors[0]);
              } else {
                Salert.error("图形验证码不正确，请重新输入！");
              }
              this.updateRucaptcha();
            },
          });
        }
      } else {
        Salert.error("验证码不合法!");
      }
    } else {
      Salert.error("手机号不合法!");
    }
  }

  submit() {
    if (!this.props.isFormValidate()) return;

    const $form = $(this.refs.form);
    $form.ajaxSubmit({
      url: $form.attr("action"),
      type: "POST",
      dataType: "json",
      success: res => {
        Salert.success("注册成功");
        this.props.setSignIn(true);
        this.props.setSm(true);
        if (res && res.redirect) {
          window.location.href = res.redirect;
        } else {
          window.location.href = "/";
        }
      },
      error: xhr => {
        Salert.error(xhr.responseJSON.errors[0]);
        // this.updateRucaptcha();
      },
    });
  }

  render() {
    const {
      data: { mobile, password, name, phone_verify_code, _rucaptcha },
      changeValue,
    } = this.props;
    const { isSend, time, src } = this.state;

    return (
      <form
        ref="form"
        action="/signup"
        className="signin-form"
        onKeyDown={e => {
          if (e.which === 13) this.submit();
        }}
      >
        <input name="utf8" type="hidden" value="✓" />
        <CustomizeInput
          {...mobile}
          onChange={value => {
            changeValue(value, "mobile");
          }}
        />
        <div className="u-pic-code">
          <CustomizeInput
            {..._rucaptcha}
            onChange={value => {
              changeValue(value, "_rucaptcha");
            }}
          />
          <img
            ref="rucaptcha"
            src={src}
            alt="图形验证码"
            onClick={e => this.updateRucaptcha(e)}
          />
        </div>
        <div className="u-code">
          <CustomizeInput
            {...phone_verify_code}
            onChange={value => {
              changeValue(value, "phone_verify_code");
            }}
          />
          <div
            className={`send-code ${isSend && "send-code--done"}`}
            onClick={() => this.sendCode()}
          >
            {isSend ? `${time}s 后获取` : "获取短信验证码"}
          </div>
        </div>
        <CustomizeInput
          {...name}
          onChange={value => {
            changeValue(value, "name");
          }}
        />
        <CustomizeInput
          {...password}
          type="password"
          onChange={value => {
            changeValue(value, "password");
          }}
        />
        <a
          className="u-btn--black signin-form__submit"
          href="javascript:;"
          alt="登录"
          onClick={() => {
            this.submit();
          }}
        >
          注册
        </a>
        <p className="signin-form__tip">
          已有账号，立即
          <a
            className="signin-form__signup"
            href="javascript:;"
            alt="登录"
            onClick={this.props.switchAction}
          >
            &nbsp;登录
          </a>
        </p>
      </form>
    );
  }
}

SignupForm.propTypes = {
  setSignIn: PropTypes.func.isRequired,
  setSm: PropTypes.func.isRequired,
  switchAction: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  mobile: buildRules("user[mobile]", "手机号码", [mobile_rule]),
  password: buildRules("user[password]", "密码", [pwd_rule]),
  name: buildRules("user[name]", "昵称", [nickname_rule]),
  phone_verify_code: buildRules("user[phone_verify_code]", "验证码", [
    phone_code_rule,
  ]),
  _rucaptcha: buildRules("_rucaptcha", "图形验证码", [rucaptcha_rule]),
})(SignupForm);
