/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";
import tipsImg from "tips-icon.png";

import {
  mobile_rule,
  phone_code_rule,
  job_rule,
  year_rule,
  rucaptcha_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../shared/CustomizeInput";
import CustomizeSelect from "../shared/CustomizeSelect";
import FormWrapper from "../utils/FormWrapper";

const TIME = 60;
// 实名表单
class SmForm extends React.Component {
  componentDidMount() {
    const layer = document.createElement("div");
    layer.className = "wait-del";
    layer.style.cssText = `position: fixed;
    top: 0;
    left: 0;
    width: ${document.body.clientWidth < 768 ? "80px" : "100%"};
    height: ${document.body.clientWidth < 768 ? "80px" : "100%"};
    background-color: transparent;
    z-index: ${document.body.clientWidth < 768 ? 100 : 99};;
    cursor: pointer;`;
    layer.addEventListener(
      "click",
      () => {
        $.ajax({
          url: "/logout",
          type: "GET",
          dataType: "json",
          success: res => {
            console.log(res);
            window.location.reload();
          },
        });
      },
      false
    );
    document.body.appendChild(layer);
  }

  state = {
    isSend: false,
    time: TIME,
    src: "/rucaptcha/",
  };

  // safari 某些版本中 这里 直接改src state 不能正确触发 view update
  // 排查出是safari图片缓存机制的问题 加时间戳解决
  updateRucaptcha() {
    this.setState({
      src: "/rucaptcha/?" + Date.now(),
    });
  }

  sendCode() {
    const {
      data: { mobile, _rucaptcha },
    } = this.props;

    if (mobile.value && mobile.valid) {
      if (_rucaptcha.value && _rucaptcha.valid) {
        if (!this.state.isSend) {
          $.ajax({
            url: "/phone_verify_code/verify_code",
            method: "POST",
            datType: "json",
            data: {
              mobile: mobile.value,
              _rucaptcha: _rucaptcha.value,
            },
            success: () => {
              const timer = setInterval(() => {
                if (this.state.time === 0) {
                  clearInterval(timer);
                  this.setState({
                    isSend: false,
                    time: TIME,
                  });
                  this.updateRucaptcha();
                }
                this.setState({
                  time: this.state.time - 1,
                });
              }, 1000);
              this.setState({
                isSend: true,
              });
            },
            error: xhr => {
              if (xhr.status === 422) {
                Salert.error(xhr.responseJSON.errors[0]);
              } else {
                Salert.error("图形验证码不正确，请重新输入！");
              }
            },
          });
        }
      } else {
        Salert.error("验证码不合法!");
      }
    } else {
      Salert.error("手机号不合法!");
    }
  }

  submit() {
    if (!this.props.isFormValidate()) return;

    const $form = $(this.refs.smForm);
    $form.ajaxSubmit({
      url: $form.attr("action"),
      type: "POST",
      dataType: "json",
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      success: res => {
        if (res.success) {
          Salert.success("成功");
          // this.props.setSm(true);
          document.querySelector(".wait-del").style.zIndex = -1;
          if (res.redirect) {
            window.location.href = res.redirect;
          } else {
            window.location.reload();
          }
        }
      },
      error: () => {
        Salert.error("图形验证码不正确，请重新输入！");
        this.updateRucaptcha();
      },
    });
  }

  render() {
    const {
      data: {
        mobile,
        phone_verify_code,
        profession,
        working_years,
        _rucaptcha,
      },
      changeValue,
    } = this.props;
    const { isSend, time, src } = this.state;

    return (
      <form
        ref="smForm"
        action="/settings/mobile/verified"
        className="sm-form"
        onKeyDown={e => {
          if (e.which === 13) this.submit();
        }}
      >
        <CustomizeInput
          {...mobile}
          onChange={value => {
            changeValue(value, "mobile");
          }}
        />
        <div className="u-pic-code">
          <CustomizeInput
            {..._rucaptcha}
            onChange={value => {
              changeValue(value, "_rucaptcha");
            }}
          />
          <img
            ref="rucaptcha"
            src={src}
            alt="图形验证码"
            onClick={e => this.updateRucaptcha(e)}
          />
        </div>
        <div className="u-code">
          <CustomizeInput
            {...phone_verify_code}
            onChange={value => {
              changeValue(value, "phone_verify_code");
            }}
          />
          <div
            className={`send-code ${isSend && "send-code--done"}`}
            onClick={() => this.sendCode()}
          >
            {isSend ? `${time}s 后获取` : "获取短信验证码"}
          </div>
        </div>
        <p className="sm-modal__tips">
          <img src={tipsImg} alt="tips" />
          根据国家法律规要求,绑定手机号获得更高级的安全保护
        </p>
        <CustomizeInput
          {...profession}
          onChange={value => {
            changeValue(value, "profession");
          }}
        />
        <CustomizeSelect
          {...working_years}
          options={[
            {
              label: "1 年内",
              value: "1年内",
            },
            {
              label: "1-3 年",
              value: "1-3年",
            },
            {
              label: "3-5 年",
              value: "3-5年",
            },
            {
              label: "5 年以上",
              value: "5年以上",
            },
          ]}
          onChange={value => {
            changeValue(value, "working_years");
          }}
        />
        <a
          className="u-btn--black signin-form__submit"
          href="javascript:;"
          alt="提交"
          style={{ marginTop: "24px" }}
          onClick={() => {
            this.submit();
          }}
        >
          提交
        </a>
      </form>
    );
  }
}

SmForm.propTypes = {
  setSm: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  mobile: buildRules("mobile_verify[mobile]", "手机号码", [mobile_rule]),
  phone_verify_code: buildRules("mobile_verify[phone_verify_code]", "验证码", [
    phone_code_rule,
  ]),
  profession: buildRules("mobile_verify[profession]", "职业", [job_rule]),
  working_years: buildRules("mobile_verify[working_years]", "从业年限", [
    year_rule,
  ]),
  _rucaptcha: buildRules("_rucaptcha", "图形验证码", [rucaptcha_rule]),
})(SmForm);
